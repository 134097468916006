
import { computed } from "vue";
import { renderData } from "@libraryHelpers/dataComponents.js";

export default {
	name: "CoreBlockSocialLinks",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props) {
		const blockHeadline = computed(() => {
			if (!props.settings?.["headline-show"]) return null;
			return props.settings.headline || null;
		});

		const blockUsername = computed(() => {
			if (!props.settings?.["username-show"]) return "";
			return props.settings.username || null;
		});

		const blockFacebook = computed(() => {
			if (props.settings?.["facebook-show"] === false) return "";
			return renderData(props.settings.facebook, props.dataSite);
		});

		const blockLinkedin = computed(() => {
			if (props.settings?.["linkedin-show"] === false) return "";
			return renderData(props.settings.linkedin, props.dataSite);
		});

		const blockInstagram = computed(() => {
			return props.settings.instagram || "";
		});

		const blockTwitter = computed(() => {
			if (props.settings?.["twitter-show"] === false) return "";
			return renderData(props.settings.twitter, props.dataSite);
		});

		const blockYoutube = computed(() => {
			return props.settings.youtube || "";
		});

		return {
			blockHeadline,
			blockUsername,
			blockFacebook,
			blockLinkedin,
			blockInstagram,
			blockTwitter,
			blockYoutube,
		};
	},
};
